import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MessagePopupService } from '../../shared/components/message-popup/message-popup.service';

export const errorCatchingInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);
  const messagePopupService = inject(MessagePopupService);

  return next(req).pipe(
    catchError(err => {
      console.error("errorCatchingInterceptor", err);
      if (err.status === 428) {
        messagePopupService.showMessage("Sesiunea a expirat, veti fi redirectionat catre pagina de login.", 'error');
        auth.logout();
      }
      if (err.status === 403 && auth.isLoggedIn()) {
        messagePopupService.showMessage(err.error, 'error');
        auth.logout();
      }
      return throwError(() => err);
    })
  );
};
