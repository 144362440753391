import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubscriptionTickets } from '../model/subscription-tickets';
import { EnvironmentService } from './environment.service';
import { Ticket } from '../model/ticket';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private http: HttpClient = inject(HttpClient);
  private envService = inject(EnvironmentService);
  private apiUrl: string = this.envService.getValue('apiUrl')

  async getForSubscription(subscriptionId: number): Promise<SubscriptionTickets> {
    const tickets$ = this.http.get<SubscriptionTickets>(
      `${this.apiUrl}/tickets/subscription/${subscriptionId}`,
      { observe: 'body', responseType: 'json' }
    );

    return await firstValueFrom(tickets$);
  }

  async getByReservation(reservationId: number): Promise<Ticket[]> {
    const statusList = this.envService.getValue('status');
    const tickets$ = this.http.get<Ticket[]>(`${this.apiUrl}/tickets/?reservation_id=${reservationId}&full=true&status=${statusList.done}&sort(+serie)`);

    return await firstValueFrom(tickets$);
  }
}